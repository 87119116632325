<template>
  <v-container fluid>
    <router-link to="/">
      <img
        :src="isSpl ? '/img/spl.png' : 'img/iq-white-bg-logo.png'"
        id="logo"
        alt="logo"
        class="m-3"
      />
    </router-link>
    <v-row class="page-wrapper h-100">
      <v-col
        cols="12"
        class="overflow-auto d-flex flex-column justify-content-between poppins h-100"
        :class="[isSpl ? 'container-wrapper-top-spl' : 'container-wrapper-top']"
      >
        <div class="h-100 mt-lg-0 signin-container-wrapper">
          <div class="signin-container h-100">
            <v-row class="justify-content-center h-100">
              <v-col
                cols="8"
                sm="4"
                md="4"
                lg="3"
                xl="2"
                class="h-100 d-flex flex-column justify-content-between"
              >
                <div
                  class="flex-grow-1 d-flex align-items-center justify-content-center"
                >
                  <router-view></router-view>
                </div>
                <div class="text-white text-center mb-3">
                  <div>
                    <img
                      src="/img/IQ-Hybrid-white.png"
                      alt="logo"
                      class="img-fluid w-80px mr-2 mb-2"
                    />
                    <span class="font-size-h6 px-2"
                      ><span>{{ currentYear }}</span
                      ><span>©</span> -
                      <span>{{ $t("all_rights_reserved") }}</span></span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GeneratePasswordLayout",
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    isSpl: function () {
      const fullPath = window.location.hostname;
      const spl = "spl.iqhybrid.com";

      if (fullPath === spl) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/auth/generate-password.scss";
</style>
